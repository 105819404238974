<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vx-card>

        <vs-table ref="table" pagination :max-items="this.$store.state.pps" search :data="chatrooms">
            
            <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

              <div class="flex flex-wrap-reverse items-center data-list-btn-container">
        
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4" style="margin-bottom: 0px !important">

                  <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon">
                    <span class="mr-2">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </button>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Delete</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Archive</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Print</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Another Action</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>

                <!-- ADD NEW -->
                <div class="dd-actions cursor-pointer mr-4 mb-4" style="margin-bottom: 0px !important">
                  <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon" @click="$router.push('/chatroom/new').catch(() => {})">
                    <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                      <span class="ml-2 text-base text-primary">Add New</span>
                  </button>
                </div>
                
                <!-- LOGIN TO CHAT -->
                <!--<div class="dd-actions cursor-pointer mr-4 mb-4" style="margin-bottom: 0px !important">
                  <a class="vs-component vs-button vs-button-primary vs-button-border includeIcon" href="/chat" target="_blank">
                    <feather-icon icon="MessageSquareIcon" svgClasses="h-4 w-4" />
                      <span class="ml-2 text-base text-primary">Login to chat</span>
                  </a>
                </div>-->
                
                <!-- REFRESH -->
                <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon" @click="refreshData()">
                  <feather-icon icon="RefreshCwIcon" svgClasses="h-4 w-4" />
                  <span class="ml-2 text-base text-primary">Refresh</span>
                </button>
              </div>
            </div>

            <template slot="thead">
                <vs-th class="add_th" sort-key="event_id">S.NO</vs-th>
                <vs-th class="add_th" sort-key="name">Name</vs-th>
                <vs-th class="add_th" sort-key="user_limit">User Limit</vs-th>
                <vs-th class="add_th" sort-key="from_date">From Date</vs-th>
                <vs-th class="add_th" sort-key="to_date">To Date</vs-th>
                <vs-th class="add_th" sort-key="status">Status</vs-th>
                <vs-th class="add_th">Action</vs-th>
            </template>

            <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                     <vs-td :data="data[indextr].name">
                        {{indextr+1}}
                    </vs-td>
                    <vs-td :data="data[indextr].name">
                        {{data[indextr].name}}
                    </vs-td>


                    <vs-td :data="data[indextr].user_limit">
                        {{data[indextr].user_limit}}
                    </vs-td>

                    <vs-td :data="data[indextr].from_date">
                        {{date_format(data[indextr].from_date)}}
                    </vs-td>

                     <vs-td :data="data[indextr].to_date">
                        {{date_format(data[indextr].to_date)}}
                    </vs-td>

                    <vs-td :data="data[indextr].status">
                        <vs-chip v-if="data[indextr].status==0" :color="'#d9534f'" class="product-order-status">InActive</vs-chip>
                        <vs-chip v-if="data[indextr].status==1" :color="'#5cb85c'" class="active_btns product-order-status">Active</vs-chip>
                        <vs-chip v-if="data[indextr].status==2" :color="'#d9534f'" class="product-order-status">Expired</vs-chip>
                        
                    </vs-td>

                    <vs-td class="whitespace-no-wrap">
                      <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete" @click.stop="$router.push('/chatroom/edit/'+tr.room_id).catch(() => {})" />
                      <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete" class="ml-2" @click="confirmDeleteRecord(tr.room_id,data[indextr].name)" />
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>

    </vx-card>
</template>

<script>
// import moduleChatroom          from '@/store/chatroom/moduleChatroom.js'

export default {
  data () {
    return {
    }
  },
  components: {

  },
  computed: {
    countries ()     { 
      return this.$store.getters['country/queriedCountries']      
    },
    chatrooms ()     { 
      return this.$store.getters['chatroom/queriedChatrooms']      
    },
  },
  methods: {
    refreshData () {
       this.$vs.loading()
      this.$store.dispatch('chatroom/fetchChatrooms').then(() => { this.$vs.loading.close() })
    },
     confirmDeleteRecord (id,name) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `You Are About To Delete Chatroom Name Of "${name}"`,
        accept: this.deleteChatroom,
        acceptText: 'Confirm',
        parameters: id
      })
    },
    deleteChatroom (parameters) {
       this.$vs.loading()
      this.$store.dispatch('chatroom/deleteChatroom', parameters).then(() => { 
                  this.$store.dispatch('chatroom/fetchChatrooms')
                  this.$vs.loading.close()
                  const error = "Chatroom Removed Successfully...!!!"
                  this.notif(error)     
       }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
            })
    },
     notif (error) {
      this.$vs.notify({
        title: 'Removed',
        text: error,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    date_format (date) {
      const d = new Date(date)
      return d.toDateString()
    },
  },
  created () {
    // this.$store.registerModule('chatroom', moduleChatrooms)
    if(this.chatrooms.length == 0){
       this.$vs.loading()
      this.$store.dispatch('chatroom/fetchChatrooms').then(() => { this.$vs.loading.close() })
    }
  },
  beforeDestroy() {
    // this.$store.unregisterModule('chatroom')
  }
}
</script>
